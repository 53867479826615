import "./App.css";
import React, { useRef } from "react";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";

import compas from "./Media/compas.png";
import sea from "./Media/sea.mp4";

import seaPrev from "./Media/seaPrev.jpg";

function App() {
  const tokenContract = "8T5ZR4vBp9Ef6Uh3Pf8NCo1F4RSHGXwd8uQGhCtYz5Vn";
  const handleClickVariant = (variant, message) => () => {
    enqueueSnackbar(message, { variant });
    copyToClipboard();
  };
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(tokenContract)
      .then(() => {
        console.log("Copied to clipboard:", tokenContract);
        // Optionally, you can show a success message or perform any other action
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        // Optionally, you can show an error message or perform any other action
      });
  };

  return (
    <>
      <SnackbarProvider
        action={(snackbarId) => (
          <button
            className="SnackbarProviderButton"
            onClick={() => closeSnackbar(snackbarId)}
          >
            ✖
          </button>
        )}
      />
      <video
        // onTimeUpdate={handleProgres}
        // ref={videoRef}
        src={sea}
        poster={seaPrev}
        autoPlay
        loop
        muted
        preload="true"
      />
      <div className="content">
        {/* <div className="banner">
          <h3
            className="red"
            onClick={() => window.open("https://twitter.com/blocksMatter")}
          >
            $BCM it is not live yet. Follow us to be the first to know.
          </h3>
        </div> */}
        <header className="App-header">
          <img className="logo" src={compas} />
        </header>
        <div className="body">
          <h1>#BlockChainsMatter</h1>
          <h2>United in a fight for the rights of all blockchains.</h2>
          <h3>$BCM available exclusively on Solana</h3>
          <h3
            title="Click to copy"
            className="contractLine"
            onClick={handleClickVariant(
              "info",
              "Token contract copied to clipboard."
            )}
          >
            Token contract: 8T5ZR4vBp9Ef6Uh3Pf8NCo1F4RSHGXwd8uQGhCtYz5Vn
          </h3>
          <div className="buttons">
            <p
              onClick={() =>
                window.open(
                  "https://dexscreener.com/solana/8rxb6bsuhdbyehzdgy34auvhy2u26g9t9rcg9u59jsca"
                )
              }
              className="button1"
            >
              CHART
            </p>
            <p
              onClick={() => window.open("https://twitter.com/blocksMatter")}
              className="button1"
            >
              TWITTER
            </p>
            <p
              onClick={() => window.open("https://t.me/blocksMatter")}
              className="button1"
            >
              TELEGRAM
            </p>
            <p
              onClick={() =>
                window.open(
                  "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=8T5ZR4vBp9Ef6Uh3Pf8NCo1F4RSHGXwd8uQGhCtYz5Vn&outputSymbol=BCM&fixed=in"
                )
              }
              className="button1"
            >
              BUY NOW
            </p>
          </div>
        </div>
        <div className="footer">
          <p
            onClick={() => window.open("https://twitter.com/The88N8")}
            className="footerTxt"
          >
            Video credit to one and only @The88N8
          </p>
        </div>
      </div>
    </>
  );
}

export default App;
